export const MAX_SHORT_TEXT_LENGTH = 255;
export const MAX_URL_LENGTH = 2048;
export const MAX_LONG_TEXT_LENGTH = 65535;
export const MAX_INTEGER = Number.MAX_SAFE_INTEGER;
export const DOLLAR_NO_DECIMAL = 0;
export const DOLLAR_MAX_DECIMALS = 2;
export const PERCENT_MAX_DECIMALS = 4;
export const PASSWORD_COMPLEXITY_MIN_LENGTH = 9;
export const PASSWORD_COMPLEXITY_SPECIAL_CHARACTERS = '!@#$%^&*()<>[]{}|_+-=';
export const PASSWORD_COMPLEXITY_MIN_SPECIAL_CHARACTERS = 1;
export const PASSWORD_COMPLEXITY_MIN_LOWERCASE_LETTERS = 1;
export const PASSWORD_COMPLEXITY_MIN_UPPERCASE_LETTERS = 1;
export const PASSWORD_COMPLEXITY_MIN_NUMBERS = 1;
export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_SET_PASSWORD = 269;
export const HTTP_STATUS_2FA_CHALLENGE = 270;
export const MAX_FILE_SIZE_MB = 50;
export const DEFAULT_PAGINATION_FILTER = { pageNumber: 1, pageSize: 25 };
export const MIN_PAGINATION_FILTER = { pageNumber: 1, pageSize: 1 };
export const MAX_PAGINATION_FILTER = { pageNumber: 1, pageSize: 2147483647 };
export const MAX_COMMENT_LENGTH = 1000;
export const TBM_MEMBER_SUPPORT_ROLE_ID = 22;
export const IMG_EXTENSIONS = ['JPG', 'JPEG', 'PNG'];
export const MAX_MEMBER_MENU_SELECTION = 10;
export const HIDE_FILE_EXTENSIONS = ['DOC', 'XLS', 'XLSB', 'JPEG'];
// Inspired from: https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url#comment129320856_8260383
export const YOUTUBE_URL_REGEX =
  /(?:youtube(?:-nocookie)?\.com\/(?:[^\\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]vi?=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
export const YOUTUBE_EMBED_URL = '//www.youtube.com/embed/';
export const YOUTUBE_THUMBNAIL_URL = 'https://img.youtube.com/vi/';
export const YOUTUBE_THUMBNAIL_URL_DEFAULT_PATH = '/default.jpg';
export const YOUTUBE_THUMBNAIL_URL_MAX_RESOLUTION_PATH = '/maxresdefault.jpg';
export const FETCH_COUNT_NOTIFICATION_INTERVAL = 60000;
export const VOWEL_REGEX = /^[aieouâêîôûäëïöüàéèùœAIEOUÂÊÎÔÛÄËÏÖÜÀÉÈÙŒ]/;
export const TRADING_DASHBOARD_INFOGRAM_ID_EN = '1f194e74-86c3-4fa2-98f0-74b387c4410b';
export const TRADING_DASHBOARD_INFOGRAM_ID_FR = '92780dc2-eaa5-4a46-bc10-0d187064cead';
export const TRADING_DASHBOARD_INFOGRAM_ID_VANCOUVER = 'cc396925-2b10-443d-96a1-28959359869c';
export const PAGINATION_ROWS_PER_PAGE_OPTIONS = [25, 50, 100];
export const PDF_CONTENT_TYPE = 'application/pdf';
export const BUY_OPPORTUNITY_PUBLICATION_CATEGORY_IDS = [2, 9, 12];
