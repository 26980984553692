import { Alert, Box, Grid, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth, useEntityFormContext, useInternationalization } from '../../../hooks';
import { VendorBuyingAgreementEntity } from '../../../models';
import { RebateCategoryEntity } from '../../../models/Vendor/RebateCategoryEntity';
import { FilterPeriod, PermissionKey, Styles } from '../../../types';
import { PeriodForm } from '../../PeriodForm';
import { VendorBuyingAgreementSelect } from '../BuyingAgreements/VendorBuyingAgreementSelect';
import { VendorRebateCategoryHeader } from './VendorRebateCategoryHeader';
import { VendorRebateCategoryDuplicateTierGrowth } from './VendorRebateCategoryDuplicateTierGrowth';
import { TargetedMembershipMemberForm, TargetedMembershipMemberGroupForm } from '../../TargetedMembership';
import { Checkbox } from '../../Form';
const style: Styles = {
  container: {
    pb: 4,
    py: 3,
    '&>*': {
      mb: 2,
    },
  },
  sectionHeader: {
    mt: 3,
    mb: 1,
  },
};

interface VendorRebateCategoryDuplicateFormProps {
  showVendorBuyingAgreementSelect?: boolean;
  rebateCategoryId: number;
  modifyMembersAndMemberGroups: boolean;
  setModifyMembersAndMemberGroups: Dispatch<SetStateAction<boolean>>;
}

export const VendorRebateCategoryDuplicateForm = ({
  showVendorBuyingAgreementSelect,
  rebateCategoryId,
  modifyMembersAndMemberGroups,
  setModifyMembersAndMemberGroups,
}: VendorRebateCategoryDuplicateFormProps) => {
  const { t } = useInternationalization();
  const { user, hasPermissions } = useAuth();
  const params = useParams();
  const vendorId = Number(user?.profile.vendor?.id ?? params.vendorId);
  const { watch, setValue, setIgnoreDirtySubmit } = useEntityFormContext<RebateCategoryEntity>();
  const vendorBuyingAgreementId = watch('vendorBuyingAgreementId');

  useEffect(() => {
    setIgnoreDirtySubmit(true);
  }, [setIgnoreDirtySubmit]);

  const onVendorBuyingAgreementChange = (value?: VendorBuyingAgreementEntity) => {
    if (value) {
      setValue('effectiveDate', value.effectiveDate);
      setValue('expiryDate', value.expiryDate);
    }
  };

  const onModifyMembersAndMemberGroupsChange = (shouldModify: boolean) => {
    setModifyMembersAndMemberGroups(shouldModify);
  };

  const filterVendorBuyingAgreement = (v: VendorBuyingAgreementEntity) =>
    v.id == vendorBuyingAgreementId || v.period == FilterPeriod.Current || v.period == FilterPeriod.Upcoming;

  return (
    <>
      <VendorRebateCategoryHeader />
      {showVendorBuyingAgreementSelect && (
        <Box sx={style.container}>
          <Grid container spacing={3}>
            <VendorBuyingAgreementSelect
              filter={filterVendorBuyingAgreement}
              onChange={onVendorBuyingAgreementChange}
              vendorId={vendorId}
              xs={12}
            />
            {vendorBuyingAgreementId && <PeriodForm readOnly xs={6} />}
          </Grid>
          {hasPermissions(PermissionKey.VendorViewRebates) && (
            <VendorRebateCategoryDuplicateTierGrowth rebateCategoryId={rebateCategoryId} vendorId={vendorId} />
          )}
          {hasPermissions(PermissionKey.MemberViewMembers) && (
            <>
              <Typography variant="h3" sx={style.sectionHeader}>
                {t('vendor:rebateCategory.sections.permissions')}
              </Typography>
              <Checkbox
                checked={modifyMembersAndMemberGroups}
                onChange={() => onModifyMembersAndMemberGroupsChange(!modifyMembersAndMemberGroups)}
                label={t('vendor:rebateCategory.form.modifyMembersAndMemberGroups')}
              />
              {modifyMembersAndMemberGroups && (
                <>
                  <Alert severity="warning">{t('vendor:rebateCategory.form.modifyMembersAndMemberGroupsNote')}</Alert>
                  <TargetedMembershipMemberGroupForm fullWidth />
                  <TargetedMembershipMemberForm fullWidth />
                </>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};
