import { useState } from 'react';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { AddCircleRounded, AddRounded } from '@mui/icons-material';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { cms as cmsApi, vendor as vendorApi } from '../../../api';
import { CmsPublicationContext } from '../../../contexts/Cms';
import { useApi, useAuth, useGlobalEdit, useInternationalization, usePageTitle } from '../../../hooks';
import { PublicationEntity, VendorBuyOpportunityEntity } from '../../../models';
import { routes } from '../../../routes';
import { PublicationSchema, VendorBuyOpportunitySchema } from '../../../schemas';
import { ContentStatus, ContentTriggers, PermissionKey, Styles } from '../../../types';
import { Container } from '../../Container';
import { EditDetails } from '../../EditDetails';
import { LayoutPage } from '../../Layout';
import { LinkTab } from '../../Tab';
import { CmsChangeLogs } from '../ChangeLog/CmsChangeLogs';
import { CmsWorkflow } from '../CmsWorkflow';
import { CmsPublicationForm } from './CmsPublicationForm';
import { palette } from '../../../styles/palette';
import { EditDrawer } from '../../EditDrawer';
import { VendorBuyOpportunityHeader } from '../../Vendor/BuyOpportunities/VendorBuyOpportunityHeader';
import { VendorBuyOpportunityForm } from '../../Vendor/BuyOpportunities/VendorBuyOpportunityForm';
import { BUY_OPPORTUNITY_PUBLICATION_CATEGORY_IDS } from '../../../constants';

const style: Styles = {
  loading: {
    mx: 2,
  },
  addBuyOpportunityButton: {
    color: palette.primary.deep,
  },
};

export const CmsPublicationDetail = () => {
  const { t, getTranslatablePropertyKey, getTranslation } = useInternationalization();
  const { hasPermissions, isVendorUser } = useAuth();
  const { globalEditing } = useGlobalEdit();
  const params = useParams();
  const outlet = useOutlet();
  const publicationId = Number(params.publicationId);
  const { data, refresh, isLoading } = useApi(cmsApi.getPublication, null, publicationId);
  const { call: update } = useApi(cmsApi.updatePublication, null);
  const { call: trigger, isLoading: isLoadingTrigger } = useApi(cmsApi.updatePublicationWorkflow, null);
  const [isAddVendorBuyOpportiunityOpen, setIsAddVendorBuyOpportunityOpen] = useState(false);
  const [vendorBuyOpportunity, setVendorBuyOpportunity] = useState(new VendorBuyOpportunityEntity());
  const { call: createBuyOpportunity } = useApi(vendorApi.createBuyOpportunity, null);
  const userRoutes = isVendorUser ? routes.Vendor : routes.Admin;
  usePageTitle(data ? getTranslation(data, 'name') : '');

  const canEdit =
    (data?.status === ContentStatus.InProgress && hasPermissions(PermissionKey.CmsNewsEdit)) ||
    ((data?.status === ContentStatus.Submitted || data?.status === ContentStatus.Approved) &&
      hasPermissions(PermissionKey.CmsNewsApprove));

  const tabs = [
    <LinkTab
      label={t('cms:publications.sections.content')}
      value={generatePath(userRoutes.PublicationDetails.Content.path, { publicationId })}
      permissions={{ keys: [PermissionKey.CmsNewsView, PermissionKey.CmsNewsEdit], any: true }}
    />,
    <LinkTab
      label={t('cms:publications.sections.targeting')}
      value={generatePath(userRoutes.PublicationDetails.Targeting.path, { publicationId })}
      permissions={{ keys: [PermissionKey.CmsNewsView, PermissionKey.CmsNewsEdit], any: true }}
    />,
  ];

  const save = async (entity: PublicationEntity) => {
    const updatedEntity = await update(entity);
    await refresh();
    return updatedEntity;
  };

  const onUpdateWorkflowConfirm = (newTrigger: ContentTriggers) => async () => {
    await trigger(publicationId, newTrigger);
    refresh();
  };

  const renderWorkflow = () => {
    if (
      !data ||
      data?.status == ContentStatus.Rejected ||
      !hasPermissions([PermissionKey.CmsNewsEdit, PermissionKey.CmsNewsApprove], true)
    ) {
      return undefined;
    }

    return (
      <CmsWorkflow
        isLoading={isLoadingTrigger}
        onTrigger={onUpdateWorkflowConfirm}
        status={data.status}
        entityName={getTranslation(data, 'name')}
        approvePermission={PermissionKey.CmsNewsApprove}
        editPermission={PermissionKey.CmsNewsEdit}
        entitySuffix={t('cms:publications.actions.suffix')}
      />
    );
  };

  const saveVendorBuyOpportunityDrawer = async (entity: VendorBuyOpportunityEntity) => {
    const result = await createBuyOpportunity(entity.vendorId ?? 0, entity);
    refresh();
    return result;
  };

  const cancelVendorBuyOpportunityDrawer = () => {
    setIsAddVendorBuyOpportunityOpen(false);
  };

  const confirmVendorBuyOpportunityDrawer = () => {
    setIsAddVendorBuyOpportunityOpen(false);
  };

  const addVendorBuyOpportunityDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <AddRounded />
          <Typography variant="drawerTitle">{t('vendor:buyOpportunities.actions.add')}</Typography>
        </Stack>
      }
      open={isAddVendorBuyOpportiunityOpen}
      entity={vendorBuyOpportunity}
      schema={VendorBuyOpportunitySchema()}
      redirectPath={(entity) =>
        generatePath(routes.Admin.Vendor.BuyOpportunity.path, {
          vendorId: vendorBuyOpportunity.vendorId,
          vendorBuyOpportunityId: entity.id,
        })
      }
      redirectLabel={t('vendor:buyOpportunities.actions.saveOpen')}
      onSave={saveVendorBuyOpportunityDrawer}
      onConfirm={confirmVendorBuyOpportunityDrawer}
      onCancel={cancelVendorBuyOpportunityDrawer}
    >
      <VendorBuyOpportunityHeader />
      <VendorBuyOpportunityForm vendorId={vendorBuyOpportunity.vendorId} />
    </EditDrawer>
  );

  // A buy opportunity can be created only if the user can edit buy opportunities,
  // the publication has a vendor, it is assigned to one of the buy opportunity categories,
  // and its status is approved.
  const canAddVendorBuyOpportunity =
    hasPermissions(PermissionKey.VendorEditBuys) &&
    data &&
    data.vendorId &&
    !data.vendorBuyOpportunityId &&
    BUY_OPPORTUNITY_PUBLICATION_CATEGORY_IDS.includes(data.publicationCategoryId ?? 0) &&
    data.status === ContentStatus.Approved;

  const onAddVendorBuyOpportunityClick = () => {
    if (canAddVendorBuyOpportunity) {
      const buyOpportunity = new VendorBuyOpportunityEntity();
      buyOpportunity.name_En = data.name_En;
      buyOpportunity.name_Fr = data.name_Fr;
      buyOpportunity.effectiveDate = data.effectiveDate;
      buyOpportunity.expiryDate = data.expiryDate;
      buyOpportunity.vendorId = data.vendorId;
      buyOpportunity.publicationId = publicationId;
      buyOpportunity.targetedMembership = data.targetedMembership;
      setVendorBuyOpportunity(buyOpportunity);
      setIsAddVendorBuyOpportunityOpen(true);
    }
  };

  const actionButtons = [
    ...(canAddVendorBuyOpportunity
      ? [
          <Button
            sx={style.addBuyOpportunityButton}
            disabled={globalEditing}
            variant="text"
            startIcon={<AddCircleRounded />}
            onClick={onAddVendorBuyOpportunityClick}
          >
            {t('cms:publications.actions.addVendorBuyOpportunity')}
          </Button>,
        ]
      : []),
    ...(data
      ? [
          <CmsChangeLogs
            key="changeLogs"
            cmsEntityId={data.id}
            fetchApi={cmsApi.getPublicationChangeLogs}
            addCommentApi={cmsApi.addPublicationComment}
            title={t('changeLog:publicationTitle')}
          />,
        ]
      : []),
  ];

  return (
    <CmsPublicationContext.Provider value={{ publication: data, canEdit, isLoading: isLoading, fetchData: refresh }}>
      <LayoutPage
        display="Detail"
        permissions={{ keys: [PermissionKey.CmsNewsView, PermissionKey.CmsNewsEdit], any: true }}
        tabs={tabs}
        outlet={outlet}
      >
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EditDetails
              titleLabel={t(`cms:publications.form.name`)}
              title={getTranslatablePropertyKey(data, 'name')}
              entity={data}
              schema={PublicationSchema()}
              onSave={save}
              onConfirm={() => refresh()}
              hideHeaderWhenEditing
              readOnly={!canEdit}
              breadcrumbs={[
                { title: t('cms:publications.title'), href: userRoutes.Cms.Publications.path },
                { title: getTranslation(data, 'name') },
              ]}
              extraActions={actionButtons}
              workflow={renderWorkflow()}
              alwaysOpen={isVendorUser}
            >
              <CmsPublicationForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
      {addVendorBuyOpportunityDrawer()}
    </CmsPublicationContext.Provider>
  );
};
