import { ContentStatus } from '../../types';
import { TargetedMembershipEntity } from '../TargetedMembership';
import { VendorEntity } from '../Vendor';
import { ContentEntity } from './ContentEntity';
import { PublicationCategoryEntity } from './PublicationCategoryEntity';

export class PublicationEntity extends ContentEntity {
  vendorId: number;
  vendor: VendorEntity;
  urgency?: string;
  isMandatory?: boolean;
  appearsOnCalendar?: boolean;
  targetedMembership?: TargetedMembershipEntity;
  category?: PublicationCategoryEntity;
  publicationCategoryId?: number;
  vendorBuyOpportunityId?: number;

  constructor(entity?: PublicationEntity) {
    super();
    if (entity) {
      Object.assign(this, { ...entity });
      this.vendor = this.vendor && new VendorEntity(this.vendor);
    } else {
      this.status = ContentStatus.InProgress;
      this.name_En = '';
      this.name_Fr = '';
      this.urgency = '';
      this.isMandatory = false;
      this.appearsOnCalendar = false;
      this.associatedLink = '';
      this.pages = [];
      this.targetedMembership = new TargetedMembershipEntity();
      this.files = [];
    }
  }
}
