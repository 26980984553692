import { Stack } from '@mui/material';
import { useContext } from 'react';
import { VendorBuyingAgreementBuyOpportunityDetailContext } from '../../../../contexts/Vendor';
import { useAuth, useInternationalization } from '../../../../hooks';
import { VendorBuyingAgreementBuyOpportunitySchema } from '../../../../schemas';
import { Styles } from '../../../../types';
import { EditCard } from '../../../Card';
import { Container } from '../../../Container';
import {
  TargetedMembershipCategorizationForm,
  TargetedMembershipMemberForm,
  TargetedMembershipMemberGroupForm,
} from '../../../TargetedMembership';

const style: Styles = {
  highlightContainer: {
    mb: 5,
  },
};

export const VendorBuyingAgreementBuyOpportunityPermissions = () => {
  const { isInternalUser } = useAuth();
  const { t } = useInternationalization();
  const { buyOpportunity, save, fetchData, readOnly } = useContext(VendorBuyingAgreementBuyOpportunityDetailContext);

  return (
    <Container isHighlighted>
      <Stack spacing={2} sx={style.highlightContainer}>
        <EditCard
          title={t('vendor:targetedMembership.title')}
          entity={buyOpportunity}
          readOnly={readOnly || (!buyOpportunity.targetedMembership?.isBasedOnMemberCriteria && !isInternalUser)}
          schema={VendorBuyingAgreementBuyOpportunitySchema()}
          onSave={save}
          onConfirm={fetchData}
        >
          <TargetedMembershipCategorizationForm />
        </EditCard>
        {isInternalUser && (
          <>
            <EditCard
              title={t('vendor:targetedMembership.form.memberGroupTitle')}
              entity={buyOpportunity}
              readOnly={readOnly}
              schema={VendorBuyingAgreementBuyOpportunitySchema()}
              onSave={save}
              onConfirm={fetchData}
            >
              <TargetedMembershipMemberGroupForm hideTitle />
            </EditCard>
            <EditCard
              title={t('vendor:targetedMembership.form.memberTitle')}
              entity={buyOpportunity}
              readOnly={readOnly}
              schema={VendorBuyingAgreementBuyOpportunitySchema()}
              onSave={save}
              onConfirm={fetchData}
            >
              <TargetedMembershipMemberForm hideTitle />
            </EditCard>
          </>
        )}
      </Stack>
    </Container>
  );
};
